import { default as indexZYDN3k3LEgMeta } from "/app/packages/kiwi-user/pages/index.vue?macro=true";
import { default as _91page_93WMZkuxs5lTMeta } from "/app/packages/kiwi-user/pages/public/article/dietqa/[permalink]/[page].vue?macro=true";
import { default as indexdiVm1Djd3NMeta } from "/app/packages/kiwi-user/pages/public/article/dietqa/[permalink]/index.vue?macro=true";
import { default as _91permalink_93qQrHtTa7xXMeta } from "/app/packages/kiwi-user/pages/public/article/dietqa/[permalink].vue?macro=true";
import { default as indexSc26jXg2B7Meta } from "/app/packages/kiwi-user/pages/public/article/dietqa/index.vue?macro=true";
import { default as _91page_93EU7UiTeLoFMeta } from "/app/packages/kiwi-user/pages/public/article/news/[permalink]/[page].vue?macro=true";
import { default as indexRHQwQBMGGYMeta } from "/app/packages/kiwi-user/pages/public/article/news/[permalink]/index.vue?macro=true";
import { default as _91permalink_939kf6Tdn9WbMeta } from "/app/packages/kiwi-user/pages/public/article/news/[permalink].vue?macro=true";
import { default as indexcY071tEEOfMeta } from "/app/packages/kiwi-user/pages/public/article/news/index.vue?macro=true";
import { default as joinSzY513R4PlMeta } from "/app/packages/kiwi-user/pages/public/healthyRecipe/[key]/join.vue?macro=true";
import { default as loginu9ZRaTFtJcMeta } from "/app/packages/kiwi-user/pages/public/healthyRecipe/login.vue?macro=true";
import { default as mypagehbB8T3aZ7YMeta } from "/app/packages/kiwi-user/pages/public/healthyRecipe/mypage.vue?macro=true";
import { default as _91adhoc_key_9342gubHsNLEMeta } from "/app/packages/kiwi-user/pages/public/healthyRecipe/passReset/[adhoc_key].vue?macro=true";
import { default as passResetStart7R6lbBIqf9Meta } from "/app/packages/kiwi-user/pages/public/healthyRecipe/passResetStart.vue?macro=true";
import { default as magazineSubscribeL1KfwGckt1Meta } from "/app/packages/kiwi-user/pages/public/magazineSubscribe.vue?macro=true";
import { default as magazineUnsubscriben6wgdQXyFRMeta } from "/app/packages/kiwi-user/pages/public/magazineUnsubscribe.vue?macro=true";
import { default as notice_of_recipeCb4F7o6KEmMeta } from "/app/packages/kiwi-user/pages/s/notice_of_recipe.vue?macro=true";
import { default as service_dietplusLqdfiSQFMdMeta } from "/app/packages/kiwi-user/pages/s/service_dietplus.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/app/packages/kiwi-user/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91permalink_93qQrHtTa7xXMeta?.name,
    path: "/public/article/dietqa/:permalink()",
    meta: _91permalink_93qQrHtTa7xXMeta || {},
    component: () => import("/app/packages/kiwi-user/pages/public/article/dietqa/[permalink].vue").then(m => m.default || m),
    children: [
  {
    name: "public-article-dietqa-permalink-page",
    path: ":page()",
    component: () => import("/app/packages/kiwi-user/pages/public/article/dietqa/[permalink]/[page].vue").then(m => m.default || m)
  },
  {
    name: "public-article-dietqa-permalink",
    path: "",
    component: () => import("/app/packages/kiwi-user/pages/public/article/dietqa/[permalink]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "public-article-dietqa",
    path: "/public/article/dietqa",
    meta: indexSc26jXg2B7Meta || {},
    component: () => import("/app/packages/kiwi-user/pages/public/article/dietqa/index.vue").then(m => m.default || m)
  },
  {
    name: _91permalink_939kf6Tdn9WbMeta?.name,
    path: "/public/article/news/:permalink()",
    meta: _91permalink_939kf6Tdn9WbMeta || {},
    component: () => import("/app/packages/kiwi-user/pages/public/article/news/[permalink].vue").then(m => m.default || m),
    children: [
  {
    name: "public-article-news-permalink-page",
    path: ":page()",
    component: () => import("/app/packages/kiwi-user/pages/public/article/news/[permalink]/[page].vue").then(m => m.default || m)
  },
  {
    name: "public-article-news-permalink",
    path: "",
    component: () => import("/app/packages/kiwi-user/pages/public/article/news/[permalink]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "public-article-news",
    path: "/public/article/news",
    meta: indexcY071tEEOfMeta || {},
    component: () => import("/app/packages/kiwi-user/pages/public/article/news/index.vue").then(m => m.default || m)
  },
  {
    name: "public-healthyRecipe-key-join",
    path: "/public/healthyRecipe/:key()/join",
    meta: joinSzY513R4PlMeta || {},
    component: () => import("/app/packages/kiwi-user/pages/public/healthyRecipe/[key]/join.vue").then(m => m.default || m)
  },
  {
    name: "public-healthyRecipe-login",
    path: "/public/healthyRecipe/login",
    meta: loginu9ZRaTFtJcMeta || {},
    component: () => import("/app/packages/kiwi-user/pages/public/healthyRecipe/login.vue").then(m => m.default || m)
  },
  {
    name: "public-healthyRecipe-mypage",
    path: "/public/healthyRecipe/mypage",
    meta: mypagehbB8T3aZ7YMeta || {},
    component: () => import("/app/packages/kiwi-user/pages/public/healthyRecipe/mypage.vue").then(m => m.default || m)
  },
  {
    name: "public-healthyRecipe-passReset-adhoc_key",
    path: "/public/healthyRecipe/passReset/:adhoc_key()",
    meta: _91adhoc_key_9342gubHsNLEMeta || {},
    component: () => import("/app/packages/kiwi-user/pages/public/healthyRecipe/passReset/[adhoc_key].vue").then(m => m.default || m)
  },
  {
    name: "public-healthyRecipe-passResetStart",
    path: "/public/healthyRecipe/passResetStart",
    meta: passResetStart7R6lbBIqf9Meta || {},
    component: () => import("/app/packages/kiwi-user/pages/public/healthyRecipe/passResetStart.vue").then(m => m.default || m)
  },
  {
    name: "public-magazineSubscribe",
    path: "/public/magazineSubscribe",
    meta: magazineSubscribeL1KfwGckt1Meta || {},
    component: () => import("/app/packages/kiwi-user/pages/public/magazineSubscribe.vue").then(m => m.default || m)
  },
  {
    name: "public-magazineUnsubscribe",
    path: "/public/magazineUnsubscribe",
    meta: magazineUnsubscriben6wgdQXyFRMeta || {},
    component: () => import("/app/packages/kiwi-user/pages/public/magazineUnsubscribe.vue").then(m => m.default || m)
  },
  {
    name: "s-notice_of_recipe",
    path: "/s/notice_of_recipe",
    meta: notice_of_recipeCb4F7o6KEmMeta || {},
    component: () => import("/app/packages/kiwi-user/pages/s/notice_of_recipe.vue").then(m => m.default || m)
  },
  {
    name: "s-service_dietplus",
    path: "/s/service_dietplus",
    meta: service_dietplusLqdfiSQFMdMeta || {},
    component: () => import("/app/packages/kiwi-user/pages/s/service_dietplus.vue").then(m => m.default || m)
  }
]