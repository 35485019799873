import revive_payload_client_bWwIQfUU1e from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.12.2_encoding@0.1.13_eslint@9.10.0_jiti@1.21_sfd3ilueuomzgeypjhzve3e2wa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_DleGYyh069 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.12.2_encoding@0.1.13_eslint@9.10.0_jiti@1.21_sfd3ilueuomzgeypjhzve3e2wa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_0zyBt0uqWJ from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.12.2_encoding@0.1.13_eslint@9.10.0_jiti@1.21_sfd3ilueuomzgeypjhzve3e2wa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_6Cu52TF2bt from "/app/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.9_rollup@4.18.1_vite@5.3.4_@types+node@20.12.2_sas_6x7tpzvfi246ewraa6v7apd3h4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_dcm2USOK0q from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.12.2_encoding@0.1.13_eslint@9.10.0_jiti@1.21_sfd3ilueuomzgeypjhzve3e2wa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_fFtNA0z7GV from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.12.2_encoding@0.1.13_eslint@9.10.0_jiti@1.21_sfd3ilueuomzgeypjhzve3e2wa/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_z8OK1shnTC from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.12.2_encoding@0.1.13_eslint@9.10.0_jiti@1.21_sfd3ilueuomzgeypjhzve3e2wa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_dAwDBFGAFk from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.1_typescript@5.5.3_vue@3.4.32_typescript@5.5.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/packages/kiwi-user/.nuxt/components.plugin.mjs";
import prefetch_client_pIQYzGHBqE from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.12.2_encoding@0.1.13_eslint@9.10.0_jiti@1.21_sfd3ilueuomzgeypjhzve3e2wa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_mAQsKXkQdu from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.8.0_encoding@0.1.13_magicast@0.3.4_next-auth@4.21.1_next@13.5.6_@babel+_7z74ut4gphfq4ydiclx5d5tgpe/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import siteConfig_BhlJgjiiUA from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.6_@nuxt+devtools@1.3.9_rollup@4.18.1_vite@5.3.4_@types+node@20._udde7epoo43v74ubk6jawvo3oi/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_xoHwu3D5q9 from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.6_@nuxt+devtools@1.3.9_rollup@4.18.1_vite@5.3.4_@types+node@20._udde7epoo43v74ubk6jawvo3oi/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import plugin_client_3raFxC0VyJ from "/app/node_modules/.pnpm/nuxt-gtag@2.0.6_magicast@0.3.4_rollup@4.18.1/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import rollbar_XnPIYyOzed from "/app/packages/kiwi-user/plugins/rollbar.ts";
export default [
  revive_payload_client_bWwIQfUU1e,
  unhead_DleGYyh069,
  router_0zyBt0uqWJ,
  _0_siteConfig_6Cu52TF2bt,
  payload_client_dcm2USOK0q,
  navigation_repaint_client_fFtNA0z7GV,
  chunk_reload_client_z8OK1shnTC,
  plugin_vue3_dAwDBFGAFk,
  components_plugin_KR1HBZs4kY,
  prefetch_client_pIQYzGHBqE,
  plugin_mAQsKXkQdu,
  siteConfig_BhlJgjiiUA,
  inferSeoMetaPlugin_xoHwu3D5q9,
  plugin_client_3raFxC0VyJ,
  rollbar_XnPIYyOzed
]