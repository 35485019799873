import validate from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.12.2_encoding@0.1.13_eslint@9.10.0_jiti@1.21_sfd3ilueuomzgeypjhzve3e2wa/node_modules/nuxt/dist/pages/runtime/validate.js";
import reset_45breadcrumbs_45global from "/app/packages/kiwi-user/middleware/resetBreadcrumbs.global.ts";
import set_45user_45agent_45global from "/app/packages/kiwi-user/middleware/setUserAgent.global.ts";
export const globalMiddleware = [
  validate,
  reset_45breadcrumbs_45global,
  set_45user_45agent_45global
]
export const namedMiddleware = {
  "pages-public-article-dietqa-permalink": () => import("/app/packages/kiwi-user/middleware/pages-public-article-dietqa-permalink.ts"),
  "pages-public-article-dietqa": () => import("/app/packages/kiwi-user/middleware/pages-public-article-dietqa.ts"),
  "pages-public-article-news-permalink": () => import("/app/packages/kiwi-user/middleware/pages-public-article-news-permalink.ts"),
  "pages-public-article-news": () => import("/app/packages/kiwi-user/middleware/pages-public-article-news.ts"),
  auth: () => import("/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.8.0_encoding@0.1.13_magicast@0.3.4_next-auth@4.21.1_next@13.5.6_@babel+_7z74ut4gphfq4ydiclx5d5tgpe/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}